<template>
  <div>
    <div
      v-if="show"
      ref="editor"
      :style="{height: height ? height+'px' : '344px', width: width ? width+'px' : '100%'}"
      class="editor"
    ></div>
  </div>
</template>

<script>
import E from "wangeditor";
import { baseUrl } from "@/common/http/api";
export default {
  name: "",
  props: {
    value: {
      type: String,
      default: "",
    },
    show: {
      type: Boolean,
      default: true,
    },
    height: {
      type: Number,
      default: 0,
    },
    width: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      editor: "",
    };
  },
  methods: {
    init() {
      this.$nextTick(() => {
        this.editor = new E(this.$refs.editor);
        this.editor.config.menus = [
          "head", // 标题
          "bold", // 粗体
          "fontSize", // 字号
          "fontName", // 字体
          "italic", // 斜体
          "underline", // 下划线
          "strikeThrough", // 删除线
          "foreColor", // 文字颜色
          "backColor", // 背景颜色
          "link", // 插入链接
          "list", // 列表
          "justify", // 对齐方式
          "quote", // 引用
          "emoticon", // 表情
          "image", // 插入图片
          "table", // 表格
          "video", // 插入视频
          "code", // 插入代码
          "undo", // 撤销
          "redo", // 重复
        ];
        this.editor.config.uploadImgShowBase64 = true;
        this.editor.config.zIndex = 10;
        this.editor.config.onchange = (html) => {
          this.$emit("editorContent", html);
        };
        // this.editor.config.uploadImgShowBase64 = true
        this.editor.config.uploadImgServer =
          baseUrl.URL + this.$api.UPLOAD.FILE;
        this.editor.config.uploadImgHeaders = {
          Authorization: "Bearer_" + localStorage.token,
        };
        this.editor.config.uploadFileName = 'file'
        this.editor.config.uploadImgParams = {
          folder: "images",
          userId: localStorage.userId,
          companyNo: localStorage.companyNo,
        };
        this.editor.config.customAlert = () => {
          // this.$Message.error('上传失败')
          return
        }
        this.editor.config.uploadImgHooks = {
          success: (xhr, editor, result) => {
            // console.log(result)
          },
          fail: (xhr, editor, result) => {
            // console.log(result)
            // editor.txt.append(`<img src=${result.data.filePath} />`)
          },
          customInsert: (insertImg, result, editor) => {
            insertImg(result.data.filePath)
          }
        }
        this.editor.create();
        if (this.value) {
          this.editor.txt.html(this.value);
        }
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>
<style>
.editor .w-e-text-container {
  height: calc(100% - 44px) !important;
}
</style>