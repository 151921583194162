<template>
  <Modal
    :value="value"
    title="新增厂房"
    @input="(val) => $emit('input', val)"
    fullscreen
    footer-hide
    @on-visible-change="workshopShow"
  >
    <x-table
      :no-data-text="CA('workshop_check') ? '暂无数据' : '暂无数据查询权限'"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @add="add"
      @search="search"
      @page-change="pageChange"
    ></x-table>

    <Modal
      v-model="modal.show"
      :title="modal.title"
      @on-visible-change="modalShow"
    >
      <Form ref="form" :model="form" :rules="rules" :label-width="110">
        <FormItem label="厂房名称" prop="name">
          <Input clearable v-model="form.name"></Input>
        </FormItem>
        <FormItem label="厂房面积" prop="area">
          <Input v-model="form.area">
            <span slot="append">㎡</span>
          </Input>
        </FormItem>
        <FormItem label="厂房绘制" prop="mapPosition">
          <Input
            placeholder="点击右侧绘制厂房地图"
            v-model="form.mapPosition"
            disabled
          >
            <span
              slot="append"
              class="iconfont"
              style="cursor: pointer"
              @click="drawMap"
              >&#xe648;</span
            >
          </Input>
        </FormItem>
        <FormItem label="备注">
          <Input
            type="textarea"
            :rows="3"
            clearable
            v-model="form.remark"
          ></Input>
        </FormItem>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" :loading="modal.submitLoading" @click="submit"
          >提交</Button
        >
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>
    <draw-map-modal
      v-model="drawMapModalShow"
      @draw-finish="drawFinish"
      :backgroundPosition="backgroundPosition"
      :mapPosition="form.mapPosition"
    ></draw-map-modal>
  </Modal>
</template>

<script>
import drawMapModal from "./drawMapModal";
import CU from "@/common/util";
export default {
  name: "",
  components: {
    drawMapModal,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    farm: Object,
  },
  data() {
    return {
      table: {
        columns: [
          {
            title: "厂房名称",
            minWidth: 110,
            key: "name",
          },
          {
            title: "所属加工厂",
            minWidth: 110,
            key: "farmName",
          },
          {
            title: "厂房面积",
            minWidth: 110,
            render: (h, { row }) => {
              return <span>{row.area + "㎡"}</span>;
            },
          },
          {
            title: "备注",
            minWidth: 100,
            key: "remark",
          },
          {
            title: "操作",
            width: 200,
            render: (h, { row }) => {
              return (
                <div>
                  {this.CA("workshop_edit") && (
                    <a
                      style="margin-right: 10px"
                      onClick={() => this.edit(row)}
                    >
                      编辑
                    </a>
                  )}
                  {this.CA("workshop_del") && (
                    <Poptip
                      confirm
                      transfer
                      title="确定删除吗?"
                      on-on-ok={() => this.del(row.id)}
                    >
                      <a>删除</a>
                    </Poptip>
                  )}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },
      form: {
        id: "",
        name: "",
        area: "",
        remark: "",
        mapPosition: "",
        thirdLongLat: "",
        siteType: '3'
      },
      rules: {
        name: [{ required: true, message: "请填写厂房编号" }],
        area: [
          { required: true, message: "请填写厂房面积" },
          {
            validator(rule, value, callback) {
              if (!CU.validateNumber(value)) {
                callback(rule.message);
                return;
              }
              callback();
            },
            message: "请填写正确的面积",
          },
        ],
        mapPosition: [{ required: true, message: "请勾画厂房" }],
      },
      farmList: [],
      drawMapModalShow: false,
      // 地图背景范围
      backgroundPosition: null,
      search_data: {},
    };
  },
  computed: {
    config() {
      let config = {
        filter: {
          add: {
            addBtnName: "新增厂房",
            ca: "workshop_add",
          },
          width: 200,
          filterBox: [
            {
              conditionName: "厂房名称",
              component: "input",
              field: "name",
              defaultValue: "",
            },
            // {
            //   conditionName: "所属加工厂",
            //   component: "input",
            //   field: "farmName",
            //   defaultValue: "",
            // },
          ],
        },
        page: { ...this.page },
      };
      return config;
    },
  },
  methods: {
    workshopShow(visible) {
      if (visible) {
        this.getList()
      }
    },
    add() {
      this.modal = {
        show: true,
        title: "新增厂房",
        submitLoading: false,
      };
    },
    search(data) {
      this.page.pageNo = 1;
      this.search_data = data;
      this.getList();
    },
    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
    drawMap() {
      let backgroundPosition = [];
      let mapPosition = JSON.parse(this.farm.mapPosition);
      mapPosition &&
        Array.isArray(mapPosition) &&
        backgroundPosition.push(...mapPosition);
      this.table.data.forEach((item) => {
        if (this.form.id && item.id == this.form.id) return;
        let tempPosition = JSON.parse(item.mapPosition);
        tempPosition &&
          Array.isArray(tempPosition) &&
          backgroundPosition.push(...tempPosition);
      });
      this.backgroundPosition = backgroundPosition;
      this.drawMapModalShow = true;
    },
    drawFinish(data) {
      this.backgroundPosition = null;
      this.form.mapPosition = JSON.stringify(data);
      let center = data.length > 0 ? JSON.parse(data[0].options.center) : {};
      this.form.thirdLongLat = center.lng + "," + center.lat;
      this.drawMapModalShow = false;
    },

    getList() {
      if (!this.CA("workshop_check")) return;
      this.table.loading = true;
      this.$post(this.$api.PRODUCTION_AREA.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        siteType: "3",
        farmId:this.farm.id,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },

    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      this.modal = {
        show: true,
        title: "编辑厂房",
        submitLoading: false,
      };
    },

    del(id) {
      this.$post(this.$api.PRODUCTION_AREA.DELETE, {
        id,
      }).then(() => {
        this.$Message.success("删除成功");
        this.getList();
      });
    },

    modalShow(visible) {
      if (visible) return;
      this.form = {
        id: "",
        name: "",
        area: "",
        remark: "",
        mapPosition: "",
        thirdLongLat: "",
        siteType: '3'
      };
      this.$refs.form.resetFields();
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        let params = { ...this.form };
        params.farmId = this.farm.id;
        this.modal.submitLoading = true;
        this.$post(
          params.id
            ? this.$api.PRODUCTION_AREA.UPDATE
            : this.$api.PRODUCTION_AREA.ADD,
          params
        )
          .then(() => {
            this.getList();
            this.modal.show = false;
          })
          .finally(() => {
            this.modal.submitLoading = false;
          });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.work-shop {
  width: 100%;
  height: 100%;
}
</style>