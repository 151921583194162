<template>
  <div class="process-factory">
    <x-table
      :no-data-text="
        CA('processFactory_check') ? '暂无数据' : '暂无数据查询权限'
      "
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @add="add"
      @search="search"
      @loadEnter="loadEnter"
      @loadExpend="loadExpend"
      @page-change="pageChange"
      @page-size-change="pageSizeChange"
    ></x-table>

    <Modal
      v-model="modal.show"
      :title="modal.title"
      @on-visible-change="modalShow"
    >
      <Form ref="form" :model="form" :rules="rules" :label-width="100">
        <FormItem label="加工厂名称" prop="name">
          <Input clearable v-model="form.name"></Input>
        </FormItem>
        <FormItem label="加工厂面积" prop="area">
          <Input v-model="form.area">
            <span slot="append">㎡</span>
          </Input>
        </FormItem>
        <FormItem label="负责人" prop="chargePerson">
          <Input clearable v-model="form.chargePerson"></Input>
        </FormItem>
        <FormItem label="负责人电话" prop="chargePersonTel">
          <Input clearable v-model="form.chargePersonTel"></Input>
        </FormItem>
        <FormItem label="详细地址" prop="address">
          <Input clearable v-model="form.address"></Input>
        </FormItem>
        <FormItem label="区域" prop="regionName">
          <Input
            v-model="form.regionName"
            readonly
            @on-focus="
              () => {
                chooseArea.show = true;
              }
            "
          ></Input>
        </FormItem>
        <FormItem label="加工厂绘制" prop="mapPosition">
          <Input v-model="form.mapPosition" disabled>
            <span
              slot="append"
              class="iconfont"
              style="cursor: pointer"
              @click="drawMap"
              >&#xe648;</span
            >
          </Input>
        </FormItem>
        <FormItem label="备注">
          <Input
            type="textarea"
            :rows="3"
            clearable
            v-model="form.remark"
          ></Input>
        </FormItem>
        <FormItem label="上传图片">
          <upload-image
            v-model="form.imgUrl"
            :max="1"
            :clear-flag="!modal.show"
          ></upload-image>
        </FormItem>
      </Form>
      <p slot="footer" style="align-right: center">
        <Button type="primary" :loading="modal.submitLoading" @click="submit"
          >提交</Button
        >
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>

    <draw-map-modal
      v-model="drawMapModalShow"
      @draw-finish="drawFinish"
      :mapPosition="form.mapPosition"
    ></draw-map-modal>
    <area-modal
      v-model="chooseArea.show"
      @on-change="getArea"
      :areaIds="areaIds"
    ></area-modal>
    <workshop v-model="workshopShow" :farm="farm"></workshop>

    <Modal
      v-model="editorModal"
      title="基地信息配置"
      :width="900"
      @on-visible-change="editorModalShow"
    >
      <editor
        ref="detail"
        @editorContent="getEditorContent"
        v-model="remark"
        v-if="editorShow"
      ></editor>
      <p slot="footer" style="text-align: center">
        <Button type="primary" :loading="editorLoading" @click="infoSubmit"
          >提交</Button
        >
        <Button @click="() => (editorModal = false)">取消</Button>
      </p>
    </Modal>
    <pic-view v-model="checkPic" :src="imgSrc"></pic-view>
  </div>
</template>

<script>
import workshop from "../workshop";
import areaModal from "@/components/areaModal/newIndex";
import drawMapModal from "./drawMapModal";
import CU from "@/common/util";
import editor from "@/components/editor";
import picView from "@/components/picture_view";
export default {
  name: "",
  components: {
    drawMapModal,
    areaModal,
    workshop,
    editor,
    picView,
  },
  data() {
    return {
      table: {
        columns: [
          {
            title: "加工厂图片",
            width: 110,
            align: "center",
            render: (h, { row }) => {
              if (row.imgUrl == "imageundefined" || row.imgUrl=='') return <span>--</span>;
              return (
                <div
                  style="padding:4px;cursor:pointer"
                  on-click={() => this.checkPicInfo(row)}
                >
                <img
                  src={this.getImgUrl(row.servicePath, row.imgUrl)}
                  style="width: 35px;height: 35px;"
                />
                </div>
              );
            },
          },
          {
            title: "加工厂名称",
            key: "name",
            minWidth: 160,
          },
          {
            title: "加工厂面积",
            width: 110,
            render: (h, { row }) => {
              return <span>{row.area + "㎡"}</span>;
            },
          },
          {
            title: "负责人",
            width: 150,
            key: "chargePerson",
          },
          {
            title: "负责人电话",
            width: 150,
            key: "chargePersonTel",
          },
          {
            title: "加工厂地址",
            width: 260,
            key: "address",
          },
          {
            title: "创建时间",
            width: 180,
            key: "createTime",
          },
          {
            title: "操作",
            width: 300,
            render: (h, { row }) => {
              return (
                <div>
                  <a
                    style="margin-right:10px"
                    onClick={() => this.infoConfig(row)}
                  >
                    基地信息
                  </a>
                  {this.CA("processFactory_edit") && (
                    <a
                      style="margin-right: 10px"
                      onClick={() => this.edit(row)}
                    >
                      编辑
                    </a>
                  )}
                  <a
                    style="margin-right: 10px"
                    onClick={() => this.addFarmArea(row)}
                  >
                    厂房
                  </a>
                  {this.CA("processFactory_del") && (
                    <Poptip
                      confirm
                      transfer
                      title="确定删除吗?"
                      on-on-ok={() => this.del(row.id)}
                    >
                      <a>删除</a>
                    </Poptip>
                  )}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },

      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },

      form: {
        id: "",
        name: "",
        area: "",
        chargePerson: "",
        chargePersonTel: "",
        imgUrl: "",
        remark: "",
        address: "",
        siteType: "3",
        mapPosition: "",
        thirdLongLat: "",
        //区域id
        regionCode: "",
        //区域名称
        regionName: "",
      },
      //已选择的区域id和名称列表
      regionCode: [],
      regionName: [],
      rules: {
        name: [{ required: true, message: "请填写加工厂名称" }],
        area: [{ required: true, message: "请填写加工厂面积" }],
        chargePerson: [{ required: true, message: "请填写负责人" }],
        chargePersonTel: [
          { required: true, message: "请填写联系电话" },
          {
            validator(rule, value, callback) {
              if (CU.validatePhone(value)) {
                callback();
                return;
              }
              if (CU.validateLandline(value)) {
                callback();
                return;
              }
              callback(rule.message);
            },
            message: "手机号格式不正确",
            trigger: "change",
          },
        ],
        address: [{ required: true, message: "请填写加工厂地址" }],
        mapPosition: [{ required: true, message: "请绘制加工厂地图" }],
        regionName: [{ required: true, message: "请选择区域" }],
      },
      drawMapModalShow: false,
      search_data: {},

      //区域选择弹窗
      chooseArea: {
        show: false,
      },
      //区域id
      areaIds: "",
      workshopShow: false,
      farm: {},
      editorModal: false,
      editorShow: false,
      remark: "",
      farmId: "",
      editorLoading: false,
      imgSrc:'',
      checkPic:false,
    };
  },
  computed: {
    config() {
      let config = {
        filter: {
          add: {
            addBtnName: "新增加工厂",
            ca: "processFactory_add",
          },
          width: 200,
          loadModel:{
            url:'https://castoff.hogdata.cn/app/加工厂管理.xlsx'
          },
          loadEnter: {
            loadName: "导入",
            ca: "terminalEquipment_import",
          },
          loadExpend:{
            loadExpendName:'导出'
          },
          filterBox: [
            {
              conditionName: "加工厂名称",
              component: "input",
              field: "name",
              defaultValue: "",
            },
            {
              conditionName: "负责人",
              component: "input",
              field: "chargePerson",
              defaultValue: "",
            },
            {
              conditionName: "负责人电话",
              component: "input",
              field: "chargePersonTel",
              defaultValue: "",
            },
          ],
        },
        page: { ...this.page },
      };
      return config;
    },
  },
  methods: {
    //导入
    loadEnter() {
      let input = document.createElement("input");
      input.type = "file";
      input.onchange = (e) => {
        if (!input.files || input.length === 0) return;
        let formdata = new FormData();
        formdata.append("file", input.files[0]);
        formdata.append("userId", localStorage.getItem("userId"));
        formdata.append("companyNo", localStorage.getItem("companyNo"));
        // formdata.append("siteType", 3);
        this.$post(this.$api.DEAL_DEVICE.UPLOAD2, formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }).then(() => {
          this.$Message.success('导入成功')
          this.getList();
        });
      };
      input.click();
    },
    //导出
    loadExpend(){
      let columns = this.table.columns.map((item)=>item.title)
      columns.pop();
      let data = this.table.data.map((row)=>{
        let rowData = this.table.columns.map((column)=>{
          if(column.title=='加工厂图片'){
            return this.getImgUrl(row.servicePath,row.imgUrl)
          }
          return row[column.key];
        })
        return rowData
      })
      CU.exportExcel(columns, data, "加工厂管理");
    },
    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
    pageSizeChange(pageSize) {
      this.page.pageSize = pageSize
      this.getList()
    },

    search(data) {
      this.search_data = data;
      this.page.pageNo = 1;
      this.getList();
    },

    add() {
      this.areaIds = "";
      this.modal = {
        show: true,
        title: "新增加工厂",
        submitLoading: false,
      };
    },
    drawMap() {
      this.drawMapModalShow = true;
    },
    drawFinish(data) {
      this.form.mapPosition = JSON.stringify(data);
      let center = data.length > 0 ? JSON.parse(data[0].options.center) : {};
      this.form.thirdLongLat = center.lng + "," + center.lat;
      this.drawMapModalShow = false;
    },
    addFarmArea(row) {
      this.workshopShow = true;
      this.farm = row;
    },

    getList() {
      if (!this.CA("processFactory_check")) return;
      this.table.loading = true;
      this.$post(this.$api.BASE_MANAGE.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        siteType: "3",
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    edit(row) {
      this.areaIds = row.regionCode;
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      this.form.imgUrl = row.imgUrl== "imageundefined" ? "" : this.getImgUrl(row.servicePath, row.imgUrl);
      this.form.regionName = row.regionCodeName;
      this.modal = {
        show: true,
        title: "编辑加工厂",
        submitLoading: false,
      };
    },
    infoConfig(row) {
      this.farmId = row.id;
      this.remark = row.remark;
      this.editorModal = true;
      this.editorShow = true;
    },
    getEditorContent(content) {
      this.remark = content;
    },
    editorModalShow(visible) {
      if (visible) return;
      this.$refs.detail.editor.txt.html("");
      this.editorShow = false;
    },

    infoSubmit() {
      this.editorLoading = true;
      this.$post(this.$api.BASE_MANAGE.EDIT, {
        id: this.farmId,
        remark: this.remark,
      })
        .then(() => {
          this.getList();
          this.editorModal = false;
        })
        .finally(() => {
          this.editorLoading = false;
        });
    },
    del(id) {
      this.$post(this.$api.BASE_MANAGE.DELETE, {
        id,
      }).then(() => {
        this.$Message.success("删除成功");
        this.getList();
      });
    },
    //获取区域id
    getArea(value) {
      this.regionCode = [];
      this.regionName = [];
      this.chooseArea.show = false;
      if (value.ids.length > 0) {
        value.ids.forEach((item) => {
          this.regionCode.push(item);
        });
        value.names.forEach((item) => {
          this.regionName.push(item);
        });
      }
      this.form.regionCode = this.regionCode.toString();
      this.form.regionName = this.regionName.toString();
    },
    modalShow(visible) {
      if (visible) return;
      this.form = {
        id: "",
        name: "",
        area: "",
        chargePerson: "",
        chargePersonTel: "",
        imgUrl: "",
        remark: "",
        address: "",
        siteType: "3",
        mapPosition: "",
        thirdLongLat: "",
        regionCode: "",
        regionName: "",
      };
      this.$refs.form.resetFields();
    },
    //查看图片
    checkPicInfo(row) {
      this.checkPic = true;
      this.imgSrc = row.servicePath + row.imgUrl;
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        let params = { ...this.form };
        params.imgUrl = "image" + this.form.imgUrl.split("image")[1];
        this.modal.submitLoading = true;
        this.$post(
          params.id ? this.$api.BASE_MANAGE.UPDATE : this.$api.BASE_MANAGE.ADD,
          params
        )
          .then(() => {
            this.getList();
            this.modal.show = false;
          })
          .finally(() => {
            this.modal.submitLoading = false;
          });
      });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.process-factory {
  width: 100%;
  height: 100%;
}
</style>
