<template>
  <Modal
    :value="value"
    title="区域选择"
    @input="(val) => $emit('input', val)"
    :width="500"
    @on-visible-change="modalShow"
  >
    <Tree
      :data="areaList"
      show-checkbox
      multiple
      ref="tree"
      :load-data="loadData"
      :loading="loading"
    ></Tree>
    <p slot="footer" style="text-align: center">
      <Button type="primary" @click="submit">提交</Button>
      <Button @click="() => $emit('input', false)">取消</Button>
    </p>
  </Modal>
</template>

<script>
import CU from "@/common/util";
export default {
  name: "",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    areaIds: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      //区域列表
      areaList: [],
      areaInfo: {
        //要提交的区域id
        ids: [],
        //要提交的区域名
        names: [],
      },
      loading: false,
      selected: new Set(),
    };
  },
  methods: {
    //获取区域列表
    getAreaList() {
      return new Promise((resolve) => {
        this.$post(this.$api.AREA_MANAGE.LIST, {
          parentId: 100000,
        }).then((res) => {
          let areaList = [];
          let postCount = 0;
          let finishCount = 0;
          for (let i = 0; i < res.length; i++) {
            let temp = {
              title: res[i].title,
              children: [],
            };
            if (this.selected.has(res[i].id + "")) {
              postCount++;
              this.getLists(res[i].parentId).then((response) => {
                CU.formatTreeComponentData(
                  response,
                  "name",
                  "children",
                  true,
                  () => false,
                  (item) => {
                    return this.selected.has(item.id + "");
                  }
                );
                temp.children = response;
                areaList.push(temp);
                finishCount++;
                if (finishCount == postCount) {
                  this.areaList = areaList;
                  resolve();
                }
              });
            } else {
              temp.loading = false;
              areaList.push(temp);
            }
          }
          res.forEach((item) => {
            if (this.selected.has(item.id + "")) {
              console.log(item);
            }
            item.title = item.name;
            item.loading = false;
            item.children = [];
            //   if (this.selected.has(item.id)) {
            //   item.expand = true;
            // this.loadData(item);
            //   }
          });
          this.areaList = res;
        });
      });
    },
    //提交区域
    submit() {
      this.areaInfo.ids = [];
      this.areaInfo.names = [];
      let items = this.$refs.tree.getCheckedAndIndeterminateNodes();
      if (items.length > 0) {
        items.forEach((item) => {
          this.areaInfo.ids.push(item.id);
          this.areaInfo.names.push(item.title);
        });
      }
      this.$emit("on-change", this.areaInfo);
    },

    //异步加载下级
    loadData(item, callback) {
      this.loading = true;
      this.$post(this.$api.AREA_MANAGE.LIST, {
        parentId: Number(item.id),
      })
        .then((res) => {
          res.forEach((item) => {
            item.title = item.name;
            item.loading = false;
            item.children = [];
            if (this.selected.has(item.id)) {
              item.checked = true;
            }
          });
          const data = res;
          callback(data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getLists(parentId) {
      return this.$post(this.$api.AREA_MANAGE.CHILDLIST, {
        parentId: parentId,
      });
      //   .then((res) => {
      //     console.log(res);

      //     res[0].children.forEach((item) => {
      //       item.title = item.name;
      //       if (this.selected.has(item.id)) {
      //         item.checked = true;
      //       }
      //     });
      //     this.areaList.forEach((item) => {
      //       if (item.id == res[0].parentId) {
      //         item.children = res;
      //       }
      //     });
      //   });
    },

    modalShow(visible) {
      if (visible) {
        if (this.areaIds) {
          this.areaIds.split(",").forEach((item) => {
            this.selected.add(item);
          });
        }
        this.getAreaList().then(() => {
          //   this.getLists();
        });
      } else {
        this.areaInfo = {
          ids: [],
          names: [],
        };
        this.areaList = [];
        this.selected.clear();
      }
    },
  },
  mounted() {
    // this.getAreaList();
  },
};
</script>

<style lang="less" scoped>
</style>